.sidebar {
  position: fixed;
  width: 18%;
  background: #fdfdfd;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  margin-top: 83px;
  box-shadow: 2px 0px 8px rgba(0, 0, 0, 0.04);
}

.angolaLogo {
  width: 90px;
  height: 73px;
}

.menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0px 25px 0px;
}

.icon {
  width: 17px;
  height: 17px;
  margin-right: 20px;
}

.icon2 {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}

.icon3 {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.logout {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border: none;
  background: transparent;
  padding-left: 20px;
  color: var(--primaryRed);
}

.logout:hover {
  cursor: pointer;
}

.link,
.linkLogout {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  width: 100%;
  height: 45px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  padding-left: 20px;
  text-decoration: none;
  color: #1d1d1d;
  letter-spacing: 1px;
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 767px) {
  link {
    display: none;
  }
  /* STYLES GO HERE */
}

.linkLogout {
  color: var(--primaryRed);
}

.linkSmall {
  margin-left: -20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  width: 87%;
  height: 36px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 16px;
  padding: 0px 20px;
  text-decoration: none;
  color: #1d1d1d;
}

.profile {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile > img {
  margin-right: 20px;
}

.profile h2:nth-child(1) {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #1d1d1d;
}

.profile h2:nth-child(2) {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  opacity: 0.5;
}

.userSubCategory {
  margin-top: 5px;
  margin-bottom: 10px;
  padding-left: 20px;
  margin-right: -75px;
  width: 90%;
}

.addSubCategory {
  margin-top: 5px;
  margin-bottom: 10px;
  padding-left: 20px;
  margin-right: -55px;
  width: 100%;
}
