.addCategory {
  width: 836px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.reSelect {
  color: var(--primaryRed) !important;
  font-weight: 700 !important;
  text-decoration: underline;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  align-self: flex-end;
  justify-self: flex-end;
  margin-right: 20px;
  cursor: pointer;
}

.dragAndDrop {
  width: 836px;
  height: 247px;
  background: #fff8f9;
  border: 0.8px dashed #031569;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dragAndDrop img {
  max-width: 200px;
  max-height: 200px;
}

.dragAndDrop h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #343434;
  margin: 10px;
}

.dragAndDrop p {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ababab;
}

.addCategory button {
  margin-bottom: 20px;
}

.flexRow {
  display: flex;
  width: 100%;
  gap: 5%;
}

.dateInput {
  gap: 2%;
  width: 95%;
  height: 64px;
  border: 1px solid #a3a3a3;
  border-radius: 8px;
  padding: 15px;
  display: flex;
}

.premiumAndId {
  display: flex;
}

.transactionDetials {
  display: flex;
  gap: 3%;
  justify-content: flex-start;
  width: 100%;
  padding: 25px;
  flex-wrap: wrap;
}

.column {
  border: #e7e7e7 1px solid;
  border-radius: 5px;
  padding: 15px;
  width: 450px;
  background-color: #f5f5f5;
  height: fit-content;
}

.alignLeft {
  margin-top: 30px;
}

.details {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
  width: 100%;
}

.details p {
  font-weight: 500;
  color: #555555;
}

.details label {
  font-weight: 600;
  color: #555555;
}

.row {
  display: flex;
  flex-direction: row !important;
  gap: 5%;
}

.row input {
  width: 160px !important;
}

.priceAndCurrency {
  display: flex;
  gap: 2%;
}

.catDesc,
.catName {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.catName label,
.catDesc label {
  margin: 20px 0px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

.catName input,
.catName textarea {
  width: 834px;
  height: 64px;
  border: 1px solid #a3a3a3;
  border-radius: 8px;
  padding: 10px 15px;
  color: #555555;
}

.catName input:focus {
  border: 1px solid red !important;
}

.inputCurrency {
  width: 600px !important;
}

.catName textarea {
  height: 94px;
}

.catDesc textarea {
  width: 834px;
  height: 143px;
  padding: 10px 15px;
  border: 1px solid #a3a3a3;
  border-radius: 8px;
  color: #555555;
}

.actionButtons {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  gap: 7.53px;
  margin: 30px 0px;
}

.updateCategory .dragAndDrop p {
  align-self: flex-end;
  margin: 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  text-decoration-line: underline;
  color: var(--primaryRed);
}

.addNewButton {
  min-width: 183px;
  height: 45px;
  background: var(--primaryRed);
  border-radius: 8px;
  border: none;
  color: white;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.3px;
  text-align: left;
  padding-left: 20px;
  display: flex;
  align-items: center;
}

.deactivatedStatus2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px !important;
  line-height: 19px;
  color: #ffffff !important;
  width: 114px;
  height: 35px;
  background: var(--primaryRed);
  border-radius: 22px;
  border: none;
  align-self: flex-start;
}

.deactivatedStatus {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px !important;
  line-height: 19px;
  color: #ffffff !important;
  width: auto;
  height: 35px;
  background: var(--primaryRed);
  border-radius: 22px;
  border: none;
  align-self: flex-start;
  margin-bottom: 20px;
  border: none;
  padding: 5px 15px;
}

.deactivatedStatus:hover,
.activeStatus:hover {
  cursor: pointer;
}

.activeStatus2 {
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px !important;
  line-height: 19px;
  color: #ffffff !important;
  width: 114px;
  height: 35px;
  background: #008000;
  border-radius: 22px;
  border: none;
}

.activeStatus {
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px !important;
  line-height: 19px;
  color: #ffffff !important;
  width: auto;
  padding: 5px 15px;
  height: 35px;
  background: #00a550;
  border-radius: 22px;
  border: none;
}
