.userContainer {
  background: #ffffff;
  border: 0.6px solid #cacaca;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-top: 88px;
}

.tabContainer {
  background: #ffffff;
  border: 0.6px solid #cacaca;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}

.tabNames {
  display: flex;
  gap: 4%;
  padding: 20px;
}

.headingAndNavigation {
  display: flex;
  padding: 20px;
  border-bottom: 0.6px solid #e7e7e7;
}

.headingAndNavigation h1 {
  margin-left: 15px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.userDetails {
  display: flex;
}

.tabBody h1 {
  margin: 20px;
}

.nameAndProfilePic {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 10px;
  border-right: 0.6px solid #e7e7e7;
}
.nameAndProfilePic img {
  width: 140px;
  height: 140px;
  border-radius: 50%;
}

.nameAndProfilePic h1 {
  margin-top: 20px;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
}

.ratingAndReviews {
  padding: 40px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 0.6px solid #e7e7e7;
  /* margin: 0px 10px; */
  min-width: max-content;
}

.ratingAndReviews h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.ratingAndReviews h1 {
  font-weight: 600;
  font-size: 48px;
  line-height: 58px;
}

.reviewStars img {
  margin-left: 5px;
}

.ratingAndReviews h4 {
  margin-top: 10px;
  text-decoration-line: underline;
}

.userdetailsAndActions {
  display: flex;
  flex-direction: column;
  padding: 40px 50px;
}

.data label {
  font-weight: 600;
  color: #767373;
}

.data h2 {
  margin-top: 7px;
  margin-bottom: 20px;
  margin-right: 40px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.data2 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.data2 label {
  margin-bottom: 20px;
  font-weight: 600;
  color: #7b7b7b;
}

.data2 h2 {
  margin-bottom: 10px;
  font-size: 18px;
}

.info {
  display: flex;
}

.actionButtons {
  display: flex;
  gap: 10%;
  margin-top: 15px;
}

.reviews {
  margin: 20px;
}

.reviewRow {
  gap: 1%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.reviews h3 {
  font-style: normal;
  font-weight: 400;
  color: black;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
