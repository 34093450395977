.scoresContainer {
  margin: 5px;
  display: flex;
  flex-wrap: wrap;
  /* gap: 1.5%; */
}

.scoreCard {
  width: 210px;
  height: 130px;
  background-color: var(--primaryRed);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  color: white;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 10px 10px;
}

.scoreCard h1 {
  font-size: 39px;
  /* width: 30%; */
  text-align: left;
  margin: 14px 10px;
}

.scoreCard h3 {
  font-weight: 400;
  font-size: 20px;
  /* width: 70%; */
  text-align: left;
  /* margin: 0px 10px; */
}
