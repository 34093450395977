.upload-container {
  width: 800px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 2rem auto;
  border: 1px dashed rgba(black, 0.2);
  border-radius: 2rem;
  transition: 0.2s box-shadow, 0.2s background-color;
  box-shadow: 0 10px 40px -30px rgba(black, 0.2);
}

.seller {
  height: 95px;
  width: 95px;
  border-radius: 50%;
  margin: 20px 0px;
}

.loader-container1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Translucent black background */
}

.loader1 {
  border: 8px solid #f3f3f3; /* Light gray */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* 
.categImgContainer img {
  width: 30%;
  height: 30px !important;
} */

.over {
  background-color: #2669ff;
  box-shadow: 0 20px 60px -30px rgba(#2669ff, 1);
}

.over h2 {
  color: white;
}

* {
  margin: 0;
  padding: 0;
  font-size: 16px;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Inter";
  font-style: normal;
}

.loader {
  font-size: 40px;
  color: var(--primaryRed);
}

:root {
  --textPrimary: #000000;
  --primaryBackground: #fdfdfd;
  --primaryRed: #fc303f;
  --rightSideBackground: #f5f5f5;
  --grey: #cecece;
  --border: #d7def3;
}

.loaderScreen {
  width: 100vw;
  height: 100vh;
  background-color: var(--primaryRed);
  display: flex;
  justify-content: center;
  align-items: center;
}

.userActions {
  width: 15%;
}

.noData {
  height: 50vh;
  margin: 0 auto;
  font-size: 25px !important;
}

.headerID {
  margin: 0px 5px 0px 10px;
  border-top-left-radius: 15px;
  width: 105px;
}

.tableImage {
}

.id {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  margin: 0px 20px;
  color: #555555;
}

.active {
  background-color: var(--primaryRed);
  color: white !important;
}

.adsImageContainer {
  width: 60px;
  height: 60px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.adsImage {
  height: 35px;
  width: 35px;
  background-color: white;
  /* border-radius: 10px; */
}

.bannerImgContainer {
  background: rgba(0, 0, 0, 0.05);
  width: 62px;
  height: 58px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bannerImage2 {
  width: 119px;
  height: 57px;
  /* border-radius: 10px; */
}

.active2 {
  color: var(--primaryRed);
}

.active img {
  -webkit-filter: invert(1) contrast(500%);
}

.container {
  background-color: var(--rightSideBackground);
  display: flex;
  flex-direction: column;
}

.addIcon {
  margin-right: 10px;
}

.userName {
  cursor: pointer;
}

.NAV {
  text-decoration: none;
}

.reason {
  width: 100%;
  height: 100px;
  padding: 10px;
  margin: 10px 0px;
}

.dashboardView {
  display: flex;
}

.right-side {
  width: 100%;
  margin-left: 17.5%;
  padding: 20px 5px 20px 20px;
  height: auto;
  min-height: 100vh;
  background: #f5f5f5;
}

.center {
  text-align: center;
}

.centerAlign {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 3%;
}

.underline {
  border-bottom: var(--primaryRed) 3px solid;
  padding-bottom: 5px;
  color: var(--primaryRed);
}

.noData {
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.btn-save {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 7.53px;
  width: 204px;
  height: 54px;
  background: var(--primaryRed);
  border-radius: 8px;
  color: #ffffff;
  border: none;
}

.btn-cancel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15.0696px 103.98px;
  gap: 7.53px;
  width: 174.81px;
  height: 54px;
  /* border: 1px solid var(--primaryRed); */
  border-radius: 8px;
  border: var(--primaryRed) 1px solid;
  color: var(--primaryRed);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  background: none;
  line-height: 19px;
}

.btn-noBorder {
  border: none;
  background: transparent;
  font-weight: 700;
  margin: 0px;
  width: 160px;
  color: #000000;
  text-align: left;
}

.btn-noBorder:hover {
  cursor: pointer;
}

.containerAdCategory {
  width: 99%;
  background: #ffffff;
  border: 0.6px solid #cacaca;
  border-radius: 8px;
  margin-left: 0px;
  margin-top: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 0px;
  min-height: 80vh;
}

.containerAdCategory .heading-container-chev {
  margin: 10px 20px 0px 20px;
  padding: 0px 0px 20px 20px;
  border-bottom: 0.6px solid #e7e7e7;
}

.containerSubRight {
  margin-top: 50px;
  width: 100%;
  padding: 5px;
  /* height: 100%; */
}

.containerSubRight .heading-container h1,
.heading-container-chev h1 {
  font-weight: 700;
  font-size: 32px;
}

.category {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  opacity: 0.89;
  margin-right: 10px;
  background-color: #d7def3;
  border-radius: 5px;
  padding: 3px;
  width: fit-content;
}

.navBar {
  width: 100%;
  background: var(--primaryBackground);
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px 0px 10px;
  box-shadow: 2px 0px 8px rgba(0, 0, 0, 0.04);
}

.profileOptions {
  display: flex;
  align-items: center;
  gap: 10%;
}

.star {
  width: 25px;
  height: 25px;
}

.profileOptions h2 {
  background: #f5f5f5;
  border-radius: 17.3325px;
  width: 145px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--primaryRed);
}

.profileOptions .adminImage {
  width: 55px;
  height: 54px;
  border-radius: 25px;
  z-index: 10;
}

.chevDown {
  width: 20px;
  height: 10px;
}

.heading-container {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.heading-container-chev {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2%;
  margin-left: 20px;
}

.filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filters button {
  margin-right: 30px;
}

.filtersUserList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  width: 55%;
}

.searchContainerQues {
  width: 343px;
  height: 48px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  background: #ffffff;
  border: 1px solid #a3a3a3;
  padding-right: 20px;
}

.searchContainerQues input {
  width: 100%;
  border: transparent;
  padding-left: 20px;
  font-size: 20px;
  background: transparent;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #555555;
}

.searchContainerQues input::placeholder {
  color: #a3a3a3;
}

.searchContainer input:focus {
  width: 800%;
  transition: width 250ms ease-in-out;
}

.searchContainerQues input::selection {
  border: none;
}

.searchContainer .magnify > img,
.searchContainerQues .magnify > img {
  width: 25px;
  padding-left: 20px;
}

.searchContainerQues .magnify > img {
  width: 30px;
  padding-left: 12px;
  margin-top: 5px;
}

.searchContainer input:focus,
.searchContainerQues input:focus {
  outline: none;
}

.editAndDelete {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.s20 {
  font-size: 20px !important;
  font-weight: 600 !important;
  margin-left: -5px;
  margin-bottom: 2.5px;
}

.modalDelete {
  width: 60px !important;
  height: 55px !important;
}

.text-Small {
  font-size: 20px !important;
}

.red {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: var(--primaryRed);
  margin-top: 5px;
}

.red2 {
  font-weight: 700;
  font-size: 18px;
  line-height: 19px;
  color: var(--primaryRed);
  margin-top: 5px;
}

.no-margin {
  margin: 0px !important;
}

.mySwiper2 {
  height: 233px;
  width: 370px;
  z-index: 0 !important;
}

.mySwiper {
  margin: 10px 0px;
  width: 285px;
  height: 70px;
  z-index: 0 !important;
}

.input-common {
  width: 24px;
  height: 24px;
}

.price {
  min-width: 80px;
}

.selectedTab {
  color: var(--primaryRed);
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 17px;
  border: none;
  background: none;
  display: block;
  padding: 4px;
}

.normalTab {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  padding: 4px;
  color: #a3a3a3;
  border: none;
  background: none;
  display: block;
}

.wrapper {
  position: relative;
}

.btn-action {
  width: 100%;
  border: none;
  background: transparent;
}

table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  height: 100%;
}

.tableCont {
  margin-top: 20px;
  height: 100%;
}

thead th {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: #000000;
}

tbody td {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: #555555;
}

.catTable thead tr th:nth-child(4) {
  width: 12%;
}

/* .catTable thead tr th:nth-child(5) {
  width: 13%;
} */

.catTable thead tr th:nth-child(6) {
  width: 3%;
}

.catTable thead tr th:nth-child(8) {
  width: 10%;
}

.tableHead {
  height: 100px;
}

thead {
  border: 1px solid #ddd;
}

tbody tr {
  border: 1px solid #ddd;
  background: white;
  height: 40px !important;
}

table td,
table th {
  /* border: 1px solid #ddd; */
  padding: 8px;
}

table tr {
  /* height: 40px; */
}

table tr:nth-child(odd) {
  background-color: var(--rightSideBackground);
}

table tr:hover {
  background-color: #ddd;
}

table th {
  height: 60px !important;
}

table th,
tfoot td {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #ffffff;
  color: black;
}

.margin-rem {
  margin-bottom: 0px !important;
}

.listUser {
  width: 140px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border: #ffffff solid 1px;
  position: absolute;
  top: 30px;
  left: -33px;
  background-color: #ffffff;
  z-index: 10;
}

.listAds {
  z-index: 10;
  width: 130px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border: #ffffff solid 1px;
  position: absolute;
  top: 30px;
  left: -60px;
  background-color: #ffffff;
}

.actionsCategories {
  display: flex;
  gap: 6%;
}

.containerSubRight .loader-container {
  margin-top: -100px;
  margin-left: -70px;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2%;
}
.fetchText {
  font-weight: 300;
}

.btn-delete2 {
  width: 35px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00a550;
  border: none;
  border-radius: 5.33333px;
}

.btn-delete {
  width: 35px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primaryRed);
  border: none;
  border-radius: 5.33333px;
}

.btn-edit {
  background: transparent;
  border: none;
}

.btn-edit:hover,
.btn-delete :hover {
  cursor: pointer;
}

.catActions {
  z-index: 10;
  width: 180px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border: #ffffff solid 1px;
  position: absolute;
  top: 30px;
  left: -100px;
  background-color: #ffffff;
}

.btn-catActions {
  border: none;
  background-color: transparent;
  height: 40px;
  width: 100%;
  color: var(--textPrimary);
}

.btn-catActions:hover {
  background-color: #d7def3;
}

.listAds:nth-child(3) {
  color: var(--primaryRed);
}

.list-item {
  padding: 10px;
  cursor: pointer;
  background-color: whitesmoke;
  list-style: none;
}
.list-item:hover,
.list-item:active {
  background-color: #f9f9f9;
}

.btn-back {
  background: transparent;
  border: none;
}

.addNewButton {
  min-width: 183px;
  height: 45px;
  background: var(--primaryRed);
  border-radius: 16px;
  border: none;
  color: white;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.3px;
  text-align: left;
  padding-left: 20px;
  display: flex;
  align-items: center;
}

.buttons {
  display: flex;
}

button {
  cursor: pointer;
}

.d-none {
  display: none;
}

.table-checkbox {
  width: 20px;
  height: 20px;
  margin-left: 15px;
}

.bannerActive {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  gap: 8px;
  width: 100px;
  height: 30px;
  background: #00a550;
  border-radius: 22px;
  color: white;
}

.editIcon {
  width: 25px;
  height: 25px;
}

.bannerDisabled {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  gap: 8px;
  width: 114px;
  height: 35px;
  background: var(--primaryRed);
  border-radius: 22px;
  color: white;
}

.userImage {
  /* border-radius: 10px; */
  width: 45px;
  height: 45px;
}

.tableFooters {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin-top: 20px;
  display: flex;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  align-items: center;
  color: black;
  padding: 0px 40px;
}

.pageInfo {
  display: flex;
}

.pageInfo input {
  width: 60px;
  padding-left: 5px;
  border-radius: 5px;
  border: 1px black solid;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagesCount {
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: black;
}

.tableNav {
  border: none;
  background: transparent;
  height: 40px;
  width: 40px;
}

.tableNav:disabled {
  opacity: 0.5;
}

.jumpButton {
  border: none;
  background: transparent;
}

.loginContainer {
  padding: 100px;
  width: 100%;
  height: 100vh;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.formBody {
  width: 300px;
}

.loginBody {
  padding: 20px;
  width: 400px;
  height: 500px;
  background: #ffffff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.formikCont {
  margin-top: 20px;
  width: 300px;
}

.form {
  position: relative;
  z-index: 1;
  background: #ffffff;
  border-radius: 10px;
  max-width: 360px;
  /* padding: 45px; */
  text-align: center;
}

.form input {
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  border-radius: 5px;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  /* font-size: 16px !important; */
}

.errorField {
  height: 30px;
  margin-bottom: 10px;
}
.addNewButton {
  min-width: 183px;
  height: 45px;
  background: var(--primaryRed);
  border-radius: 8px;
  border: none;
  color: white;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.3px;
  text-align: left;
  padding-left: 20px;
  display: flex;
  align-items: center;
}

/* .a2 {
  position: relative;
  top: 70%;
  left: 30%;
} */

/*Dropdown Classes   */

.myOptionClassName {
  /* background-color: red; */
}

.dropdown {
  /* width: 50%; */
  height: 64px;
  /* min-width: fit-content; */
}

.dropdown2 {
  /* width: 30%; */
  height: 64px;
}

.heading-font {
  font-size: 24px !important ;
  font-weight: 500 !important;
}

.myPlaceholderClassName {
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #555555;
}

.myControlClassName {
  /* background-color: orange; */
  border: 1px solid #a3a3a3;
  border-radius: 8px;
  width: 200px;
}

.myMenuClassName {
  /* background-color: #008000; */
  border: 1px solid #a3a3a3;
  border-radius: 5px;
  margin-top: 5px;
}

.myArrowClassName {
  margin-top: 12px;
}

.dashLoader {
  margin-top: 30px;
}

.white {
  color: white;
}
.a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form input:focus {
  background: #dbdbdb;
}

.form button {
  text-transform: uppercase;
  outline: 0;
  background: var(--primaryRed);
  width: 100%;
  border: 0;
  border-radius: 5px;
  /* padding: 15px; */
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
  margin-top: 20px;
  height: 50px;
  font-size: 20px;
  font-weight: 500px;
  letter-spacing: 1px;
}

.green {
  color: #008000;
}

.form button:active {
  background: #395591;
}

.form span {
  font-size: 40px;
  color: var(--primaryRed);
  margin-bottom: 25px;
  display: block;
}

.form p.error {
  margin: 0 0 10px 10px;
  text-align: left;
  font-size: 15px;
  color: red;
}
