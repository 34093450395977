.headerID {
  margin: 0px 20px;
  border-top-left-radius: 15px;
}
.id {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  margin: 0px 20px;
  color: #555555;
}

.activeAd {
  color: green;
}

.inactiveAd {
  color: red;
}

.para {
  width: 80%;
}

.categImgContainer img {
  width: 30px !important;
  height: 30px !important;
}

.categoryImage {
  width: 30%;
  height: 30px !important;
}
