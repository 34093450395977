.seller {
  display: flex;
  flex-direction: column;
}

.adDetail {
  display: flex;
  gap: 2%;
  width: 100%;
  padding: 15px 15px 40px 15px;
}

.swiperAndStats {
  border-right: 0.6px solid #e7e7e7;
  padding-right: 25px;
  display: flex;
  flex-direction: column;
}

.statCardstatus {
  display: flex;
  gap: 10%;
}

.statCardstatus {
  height: auto;
}

.statCards {
  margin-top: 150px;
  display: flex;
  gap: 2.5%;
}

.statCard {
  width: 117.68px;
  height: 94.77px;
  background: #f2f2f2;
  border: 0.624847px solid #c6c6c6;
  border-radius: 4.16564px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.statCard h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  color: #6c7072;
}

.statCard h2 {
  font-weight: 600;
  font-size: 24.9939px;
  line-height: 30px;
  color: #000000;
}

.statCard img {
  height: 12px;
}

.swiper {
  width: 370px;
  height: 200px;
}

.adDetailed {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.greenPerc {
  font-weight: 500;
  font-size: 14.5798px;
  line-height: 18px;
  color: #008000;
}

.statCardStatus {
  display: flex;
}

.statCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px;
}

.status {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sellerDetails {
  display: flex;
  align-items: center;
  gap: 2.5%;
}

.sellerDetails img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.sellerDetails h2 {
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;
  /* text-decoration-line: underline; */

  color: #000000;
}

.location {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 3%;
  margin: 20px 0px;
}

.location p {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: var(--primaryRed);
}

.text-Small {
  font-size: 20px !important;
}

.actionButtons {
  display: flex;
  gap: 5%;
  margin-top: 30px;
}

.activeAndPremium {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10%;
}

.premiumIndicator {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 24px;
  gap: 8px;
  width: 141px;
  height: 35px;
  background: #ffa500;
  border-radius: 22px;
  color: white;
}

.activeIndicator {
  margin-top: -20px;
}

.deactivatedStatus {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px !important;
  line-height: 19px;
  color: #ffffff !important;
  width: 140px;
  height: 35px;
  background: var(--primaryRed);
  border-radius: 22px;
}

.activeStatus {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px !important;
  line-height: 19px;
  color: #ffffff !important;
  width: 114px;
  height: 35px;
  background: #00a550;
  border-radius: 22px;
}

.activeSince {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #555555;
}

.category {
  margin: 30px 0px;
}

.category h2 {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #555555;
}

.adTitle h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 20px;
  color: #000000;
}

.price {
  font-weight: 800;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: 0.04em;
  color: var(--primaryRed);
  margin-bottom: 20px;
}

.seller {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: #555555;
}

.desc {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 10px;
}

.about {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #7b7b7b;
}

.locationText {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #000000;
  min-width: fit-content;
}

.removeAd {
  border: 1px solid #c6c6c6;
  width: 630px;
  height: auto;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 10px;
  z-index: 100;
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 40px;
}

.cancelCross {
  align-self: flex-end;
}

.removeAd h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #7b7b7b;
  margin: 10px 0px;
}

.reportsTabContainer {
  width: 100%;
  /* border: #000000 0.5px solid; */
  padding: 20px;
  /* font-size: 10px; */
}

.reportsTabContainer h2 {
  border-bottom: #fc303f 2px solid;
  padding-bottom: 5px;
  width: 150px;
}

.reportHeader h3 {
  font-weight: 500;
  padding: 10px 0px;
  font-size: 15px;
}

.remarkCard {
  border-bottom: #e7e7e7 0.25px solid;
  /* border-radius: 10px; */
  width: 50%;
  padding: 5px 0px;
  margin: 5px 0px;
}
